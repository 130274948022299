body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

/* App */
.App {
  overflow-y: auto;
}
.container {
  text-align: center;
}

/* Navbar */

.navbar {
  /* background-image: url("../Images/background2.jpg");
  background-size: 100% 200%;
  background-position: center; */
  background-color: #333;
  height: 5rem;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.navbar-logo {
  margin-top: 5rem;
  display: flex;
  height: auto;
  width: 10rem;
  padding-left: 2rem;
  gap: 1rem;
}

.navbar-nav {
  display: flex;
  gap: 1rem;
  list-style: none;
  align-items: center;
}

.navbar a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
.navbar-nav a.active {
  color: #d1dc0b;
}
.navbar a:hover {
  /* color: #f5f5f5; */
  background-color: #583b0e;
}

/* Footer */
.footer {
  display: flex;
  justify-content: center;
  background-color: #333;
  color: #fff;
  align-items: center;
  height: fit-content;
}

.subfooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 20%;
}

.footer-logo {
  display: flex;
  height: auto;
  width: 7rem;
  gap: 1rem;
}

/* home */

.welcome {
  display: flex;
  flex-direction: column;
  height: 50vh;
  background-image: url("../Images/telecommunications.jpg");
  background-position: center;
  justify-content: center;
  margin-top: 2rem;

  margin-left: 1rem;
  margin-right: 1rem;
}

.W_content {
  padding: 0.5rem;
  width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Services {
  background-image: url("../Images/backdrop.jpg");
  background-size: cover;
  background-position: center;

  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;

  font-size: larger;
  font-style: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.services_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services_content ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* space the items evenly */
  align-items: center;
  width: 100%; /* take up the full width of the parent */
  padding: 0; /* remove padding */
  margin: 0; /* remove margin */
}

.services_content li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  margin: 1rem;
  font-size: medium;
}
.icon {
  font-size: 3rem;
}
